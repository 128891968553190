import boom from 'boom'
import { logError, logWarn } from 'lib/logging'

export type boomTypes =
  | `badRequest`
  | `badImplementation`
  | `unauthorized`
  | `forbidden`
  | `notFound`
  | `expectationFailed`

/**
 * assert that the predicate is true. if it is not, throw the specified type of boom error
 * @param predicate
 * @param message
 * @param boomtType
 */
export function assertBoom(predicate: unknown, message?: string, boomType: boomTypes = `notFound`): asserts predicate {
  if (!predicate) {
    try {
      throw (boom[boomType] as any)(message)
    } catch (err) {
      if (boomType === `unauthorized`) {
        logError(`[auth error]`, err)
      } else if (boomType === `badImplementation`) {
        logError(`[server error]`, err)
      }
      throw err
    }
  }
}

// throws badRequest
export function assertGood(predicate: unknown, ...message: any): asserts predicate {
  assertBoom(predicate, message?.map((i) => String(i)).join(` `) || `bad request`, `badRequest`)
}

// throws unauthorized
export function assertAllowed(predicate: unknown, ...message: any): asserts predicate {
  if (!predicate) {
    logWarn(`[auth error]`, message)
    throw boom.unauthorized()
  }
}

// throws notFound
export function assertFound(predicate: unknown, ...message: any): asserts predicate {
  assertBoom(predicate, message?.map((i) => String(i)).join(` `) || `not found`, `notFound`)
}

// throws badImplementation
export function assertWorks(predicate: unknown, message?: string): asserts predicate {
  assertBoom(predicate, message || `server error`, `badImplementation`)
}
